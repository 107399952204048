import {Link, useLocation} from "react-router-dom";

import Styles from "./TabBar.module.css";

export default function TabBar({navItems, second = false}) {

    const location = useLocation();

    return (
        <nav className={second ? Styles.navList + ' ' + Styles.second : Styles.navList}>
                {navItems.map((item) => (
                        <Link to={item.route} className={
                            location.pathname.includes(item.route) ? Styles.navItem + ' ' + Styles.active : Styles.navItem
                        }>{item.label}</Link>
                ))}
        </nav>
    );
}