import {Outlet} from "react-router-dom";
import Styles from './Portfolio.module.css';
import TabBar from "../../components/TabBar";

const navLinks = [
    {label: 'SAE - Fast&Fabulous', route: 'fastnfabulous'},
    {label: 'Stage - Sogeti', route: 'stageS4'},
];

export default function PortfolioBUT2() {
    return (
        <>
            <TabBar navItems={navLinks} second/>

            <div className={Styles.mainContent}>
                <Outlet/>
            </div>
        </>
    );
}