import React from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import NavBar from './components/NavBar';

import HomePage from './pages/HomePage/HomePage';

import CVPage from "./pages/CVPage/CVPage";

import Portfolio from './pages/Portfolio';
import PageEnConstruction from "./pages/PageEnConstruction";

import Styles from './App.module.css';
import {AnimatePresence} from "framer-motion";
import PortfolioBUT1 from "./pages/Portfolio/PortfolioBUT1";
import BUT1 from "./pages/Portfolio/PortfolioBUT1/BUT1";

import PortfolioBUT2 from "./pages/Portfolio/PortfolioBUT2";
import BUT2 from "./pages/Portfolio/PortfolioBUT2/BUT2";
import StageSogeti from "./pages/Portfolio/PortfolioBUT2/StageSogeti";

const navLinks = [{label: 'Accueil', route: '/'}, {label: 'CV', route: '/cv'}, {
    label: 'Portfolio',
    route: '/portfolio'
},];

function App() {

    const location = useLocation();

    return (<>
            <NavBar navItems={navLinks}/>
            <main className={Styles.mainContainer}>
                <AnimatePresence mode="wait">
                    <Routes key={location.pathname} location={location}>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/cv" element={<CVPage/>}/>
                        <Route path="/portfolio" element={<Portfolio/>}>
                            <Route index element={<PageEnConstruction/>}/>
                            <Route path="but1" element={<PortfolioBUT1/>}>
                                <Route index element={<BUT1/>}/>
                                <Route path="recapgemini" element={<PageEnConstruction/>}/>
                                <Route path="stuckwin" element={<PageEnConstruction/>}/>
                                <Route path="ecoquartier" element={<PageEnConstruction/>}/>
                                <Route path="bikeufc" element={<PageEnConstruction/>}/>
                                <Route path="merelle" element={<PageEnConstruction/>}/>
                            </Route>
                            <Route path="but2" element={<PortfolioBUT2/>}>
                                <Route index element={<BUT2/>}/>
                                <Route path="fastnfabulous" element={<PageEnConstruction/>}/>
                                <Route path="stageS4" element={<StageSogeti/>}/>
                            </Route>
                            <Route path="but3" element={<PageEnConstruction/>}>

                            </Route>
                        </Route>
                    </Routes>
                </AnimatePresence>
            </main>
        </>);
}

export default App;
