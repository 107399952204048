import AnimatedPage from "../../../AnimatedPage";
import CustomSection from "../../../components/CustomSection";

export default function BUT1() {
    return (
        <AnimatedPage>
            <h1 style={{color: 'var(--primary-color)'}}>Réferentiel de compétences</h1>
            <CustomSection title="Réaliser un développement d’application">
                <p><b>Développer des applications informatiques simples</b></p>
                <ul>
                    <li>Conception de pages web avec HTML, CSS, JavaScript.</li>
                    <li>Maîtrise d'un langage de programmation moderne, notamment <b>Python</b> et <b>Java</b>.</li>
                    <li>Familiarité avec les frameworks et les concepts avancés de développement web et logiciel.</li>
                    <li>Utilisation avancée des structures de contrôle, des fonctions et des concepts fondamentaux de la
                        programmation.
                    </li>
                </ul>
            </CustomSection>

            <CustomSection title="Optimiser des applications informatiques">
                <p><b>Appréhender et construire des algorithmes</b></p>
                <ul>
                    <li>Application d'algorithmes essentiels tels que les algorithmes de tri et de recherche.</li>
                    <li>Manipulation efficace des structures de données telles que les listes, les piles, les files, les
                        arbres et les graphes.
                    </li>
                </ul>
            </CustomSection>

            <CustomSection title="Administrer des systèmes informatiques communicants complexes">
                <p><b>Installer et configurer un poste de travail</b></p>
                <ul>
                    <li>Compréhension approfondie des principes fondamentaux des systèmes d'exploitation.</li>
                    <li>Gestion des processus, de la mémoire, des fichiers et des E/S.</li>
                    <li>Compréhension approfondie des réseaux informatiques et des protocoles courants.</li>
                </ul>
            </CustomSection>

            <CustomSection title="Gérer des données de l’information">
                <p><b>Concevoir et mettre en place une base de données à partir d'un cahier des charges client</b>
                </p>
                <ul>
                    <li>Représentation graphique des modèles de données (Construction MCD / UML)</li>
                    <li>Utilisation avancée du langage SQL pour la manipulation des bases de données relationnelles.
                    </li>
                </ul>
            </CustomSection>

            <CustomSection title="Conduire un projet">
                <p><b>Identifier les besoins métiers des clients et des utilisateurs</b></p>
                <ul>
                    <li><b>Compréhension Client :</b> Interaction avec les clients, utilisation de techniques
                        d'entrevue.
                    </li>
                    <li><b>Besoin Utilisateur :</b>Collecte et analyse des besoins des utilisateurs, recours à des
                        méthodologies comme les ateliers et prototypes.
                    </li>
                </ul>
            </CustomSection>

            <CustomSection title="Travailler dans une équipe informatique">
                <p><b>Identifier ses aptitudes pour travailler dans une équipe</b></p>
                <ul>
                    <li>Participation active à des projets pratiques, démontrant des compétences en gestion de projet,
                        collaboration et gestion de problèmes.
                    </li>
                </ul>
            </CustomSection>
        </AnimatedPage>
    );
}