import {useEffect} from "react";
import Styles from './HomePage.module.css';
import {FaTerminal} from "react-icons/fa6";
import AnimatedPage from "../../AnimatedPage";

export default function HomePage() {

    const data = [
        {
            AboutDevTypeText: "Etudiant en Informatique - Développeur full-stack          "
        }
    ];

    const TypingText = ({text}) => {
        useEffect(() => {
            let i = 0;
            let isTag = false;
            const element = document.getElementById('AboutDevTypeText');
            const type = () => {
                const displayText = text.slice(0, ++i);
                if (displayText === text) return;
                element.innerHTML = displayText;
                const char = displayText.slice(-1);
                if (char === "<") isTag = true;
                if (char === ">") isTag = false;
                if (isTag) return type();
                setTimeout(type, 100);
            };
            type();
        }, [text]);

        return <>
            <span id="AboutDevTypeText" className="typeing"></span>
            <span className={Styles.blinker}>&#32;</span>
        </>
    };

    return (
        <AnimatedPage>
            <div className={Styles.top}>
                <div className={Styles.title}>
                    Nathan <b>BOSCHI</b>
                </div>
                <div className={Styles.subtitle}>
                    <FaTerminal/>
                    &nbsp;
                    <TypingText text={data[0].AboutDevTypeText}/>
                </div>
            </div>
        </AnimatedPage>
    )
}