import {Outlet} from "react-router-dom";
import TabBar from "../components/TabBar";

export default function Portfolio() {

    const navItems = [
        {label: (<>BUT 1<sup>ère</sup> année</>), route: 'but1'},
        {label: (<>BUT 2<sup>ème</sup> année</>), route: 'but2'},
        {label: (<>BUT 3<sup>ème</sup> année</>), route: 'but3'}
    ];

    return (
        <>
            <TabBar navItems={navItems}/>

            <Outlet/>
        </>
    );
}