import {motion} from 'framer-motion';
import CustomSection from "../../../components/CustomSection";
import AnimatedPage from "../../../AnimatedPage";

import SogetiLabsLogo from "../../../assets/images/sogetilabs-logo-355x115.png";
import ChronosScreenshot from "../../../assets/images/chronos.png";
import ReactLogo from "../../../assets/images/react.svg";
import DjangoLogo from "../../../assets/images/django.jpg";
import ButtonDocIDE from "../../../assets/images/doc.png";
import ImputationComponentExample from "../../../assets/images/imputationcomponent.png";
import AzurePipelines from "../../../assets/images/pipelines-image-designer.png";
import ScrumMethodology from "../../../assets/images/scrum-methodology.webp";

export default function StageSogeti() {
    return (<>
            <AnimatedPage>
                <h1>Stage - Semestre 4 - Sogeti</h1>
                <br/>
                <CustomSection title="Présentation générale">
                    <p>
                        J'ai effectué mon stage de BUT2 dans le groupe <b>Capgemini</b>, plus précisément dans la
                        division <b>SogetiLabs</b>, laboratoires de recherche et développement interne de <b>Sogeti part
                        of
                        Capgemini</b>, marque du groupe dédiée aux conseils et développements informatiques.
                    </p>
                    <p><b>Ce stage s'est déroulé d'avril à juillet 2024.</b></p>

                    <p>Ce portfolio présente certains aspects et situations rencontrées lors de ce stage. Il permet d'apprécier les compétences acquises pendant ce stage à travers quelques exemples. Il ne présente, en aucun cas, les missions et tâches réalisées durant mon stage de façon exhaustive.</p>

                    <img src={SogetiLabsLogo} width={250} style={{
                        marginTop: 40,
                        backgroundColor: 'var(--light-color)',
                        borderRadius: 20,
                        borderTop: '1rem solid var(--primary-color)'
                    }} alt="Logo des SogetiLabs"/>

                    <p>SogetiLabs est une division interne de Sogeti qui a pour vocation de placer Sogeti comme une
                        entreprise à la pointe des technologies. Pour ce faire, cette division <b>forme et accueille</b>,
                        sur des projets de R&D internes (et parfois externes),
                        des <b>stagiaires</b>, <b>alternants</b> et des <b>consultants en inter-contrat</b> (entre 2
                        missions client).</p>
                    <p>Ces consultants peuvent rester dans les labs pendant une durée très courte ou pendant plusieurs mois.
                        Cette courte durée occasionne un très grand turnover des équipes et les projets voient leurs
                        membres souvent renouvelés.</p>
                </CustomSection>
            </AnimatedPage>
            <motion.div
                initial={{opacity: 0}}
                whileInView={{opacity: 1}}
                transition={{duration: 0.6, delayChildren: 0.6, staggerChildren: 0.4}}
            >
                <CustomSection title="Contexte">
                    <p>Les entrées/sorties, imputation de l'activité, suivi des projets et de leurs livrables sont gérés
                        dans plusieurs tableaux Excel. Ces tableaux sont devenus difficiles à maintenir et à
                        utiliser.</p>

                    <br/>

                    <motion.img
                        initial={{opacity: 0, zoom: 0.95}}
                        whileInView={{opacity: 1, zoom: 1}}
                        transition={{duration: 0.6}}


                        src={ChronosScreenshot} style={{
                        width: '1000px', maxWidth: '100%', display: 'block', margin: 'auto', borderRadius: 20,
                    }}
                        alt="Capture d'écran du tableau Excel Chronos"
                    />

                    <p>Pour la partie "imputation de l'activité" (saisie des heures passées sur les projets), <b>les
                        consultants
                        remplissent eux-mêmes ce fichier</b> et <b>dupliquent leur saisie dans une
                        application
                        déjà
                        existante : TEWEB</b> (pour la facturation/paie). Cette redondance est source
                        d'erreurs et de perte de temps.</p>

                    <p>De plus, toutes ces informations sont vérifiées à chaque niveau hiérarchique supérieur. Le Chef
                        de
                        Projet
                        vérifie tous les mois les imputations des consultants, les responsables scientifiques effectuent
                        la
                        même
                        vérification pour les imputations des projets de leur périmètre, et cela, encore sur deux
                        niveaux.</p>
                </CustomSection>
            </motion.div>

            <motion.div
                initial={{opacity: 0, x: 100}}
                whileInView={{opacity: 1, x: 0}}
                transition={{duration: 0.6}}
            >
                <CustomSection title="Le projet">
                    <p>Le but de mon stage était de <b>concevoir et développer une application web</b> permettant de
                        réaliser toutes ces opérations, pour au final s'affranchir des tableaux Excel.</p>

                    <p>Cette application devait permettre de :</p>
                    <ul>
                        <li>Récupérer les données saisies par les consultants sur TEWEB.</li>
                        <li>Proposer la validation des supérieurs hiérarchiques des imputations.</li>
                        <li>Permettre aux responsables scientifiques de suivre l'avancement des projets (E/S,
                            imputations,
                            livrables).
                        </li>
                    </ul>

                    <p>À mon arrivée sur le projet, une designeuse UI/UX avait déjà réalisée quelques maquettes. Le
                        cahier
                        des charges était en cours de construction. J'ai donc pu participer à l'élaboration de ce
                        dernier.</p>

                    <div style={{
                        display: 'flex', gap: 30, marginTop: 20
                    }}>
                        <img src={ReactLogo} width={100} height={100} style={{borderRadius: 15}} alt="Logo ReactJS"/>
                        <img src={DjangoLogo} width={100} height={100} style={{borderRadius: 15}} alt="Logo Django"/>
                    </div>

                    <p>Le projet a été réalisé en <b>React</b> (JavaScript) pour le front-end et
                        en <b>Django</b> (Python)
                        pour le back-end. Le SGBDR utilisé est <b>PostgreSQL</b>.</p>
                </CustomSection>
            </motion.div>

            <motion.div
                initial={{opacity: 0}}
                whileInView={{opacity: 1}}
                transition={{duration: 0.6}}
            >
                <CustomSection title="Développement d'une bibliothèque de composants réutilisables">
                    <p style={{color: "var(--primary-color)"}}>
                        Compétences mises en œuvre :
                        <ul>
                            <li>
                                <b>Réaliser un développement d’application [Partir des exigences et aller jusqu'à une application complète]</b>
                                <ul style={{color: 'var(--secondary-color)'}}>
                                    <li>Élaborer et implémenter les spécifications fonctionnelles et non fonctionnelles à partir des exigences</li>
                                    <li>Appliquer des principes d'accessibilité et d'ergonomie</li>
                                    <li>Adopter de bonnes pratiques de conception et de programmation</li>
                                    <li>Vérifier et valider la qualité de l'application par les tests</li>
                                </ul>
                            </li>
                            <li>
                                <b>Conduire un projet [Appliquer une démarche de suivi de projet en fonction des besoins métiers]</b>
                                <ul style={{color: 'var(--secondary-color)'}}>
                                    <li>Formaliser les besoins du client et de l'utilisateur</li>
                                </ul>
                            </li>
                        </ul>
                    </p>

                    <p>Après avoir fait quelques recherches sur l'identité visuelle de Capgemini, j'ai découvert
                        qu'aucune
                        bibliothèque de composants graphiques correspondant à la charte graphique de Sogeti
                        n'existait.</p>

                    <p>Pour structurer l'application, faciliter le développement et la maintenance, j'ai proposé l'idée
                        de créer des composants qui seraient polyvalents et réutilisables.</p>
                    <p><b>Ces composants sont des éléments d'interface graphique qui peuvent être utilisés dans
                        plusieurs pages de l'application.</b> Il s'agit de <i>Boutons</i>, <i>Champs de saisie</i>, <i>Fenêtres
                        contextuelles</i>... et certains autres composants plus spécifiques à notre application (~30
                        composants/variantes).</p>
                    <p>Cela permet également de garantir une <b>cohérence graphique</b> et une <b>homogénéité</b> dans
                        l'application, en appliquant la même charte graphique et les mêmes règles de design partout.</p>

                    <div
                        style={{
                            backgroundColor: 'var(--light-color)',
                            color: 'var(--dark-color)',
                            padding: '5px 20px',
                            borderRadius: 20,
                            marginTop: 20
                        }}
                    >
                        <p>J'ai conçu cette bibliothèque pour la rendre la moins spécifique à notre projet
                            possible. <b>L'objectif
                                ?</b> Que cette bibliothèque puisse également être réutilisée pour d'autres projets dans
                            le futur.</p>
                    </div>

                    {/********************************************************************/}
                    <h3>La documentation</h3>
                    <p>Chaque composant est accompagné d'une documentation détaillée qui explique comment l'utiliser,
                        comment le
                        configurer avec, au minimum, un exemple d'utilisation.</p>
                    <p>La documentation est rédigée en entête de chaque composant dans la jsdoc. Cela permet aux IDEs de
                        proposer cette documentation interactivement lors du développement.</p>
                    <div>
                        <motion.img
                            initial={{opacity: 0, zoom: 0.6}}
                            whileInView={{opacity: 1, zoom: 1}}
                            transition={{duration: 0.6}}
                            src={ButtonDocIDE} width={500} style={{borderRadius: 20, marginLeft: 10}}
                            alt={"Exemple de documentation d'un composant bouton dans l'IDE"}
                        />
                    </div>

                    {/********************************************************************/}
                    <h3>Les tests unitaires</h3>

                    <p>Chaque composant est accompagné de tests unitaires qui permettent de vérifier que le composant se
                        comporte correctement en toutes circonstances. Cela permet également de vérifier que les
                        modifications apportées au composant, plus tard dans le développement de l'application, ne
                        cassent
                        pas son fonctionnement initial.</p>
                    <p>Les tests unitaires sont réalisés avec le module <i>jest</i> de JavaScript.</p>

                    <p><b>Pour les composants réutilisables, je suis arrivé à un taux de couverture de 100%.</b></p>
                </CustomSection>
            </motion.div>
            <motion.div
                initial={{opacity: 0, x: 100}}
                whileInView={{opacity: 1, x: 0}}
                transition={{duration: 0.6}}
            >
                <CustomSection title='Le composant "imputation"'>
                    <p style={{color: "var(--primary-color)"}}>
                        Compétences mises en œuvre :
                        <ul>
                            <li>
                                <b>Réaliser un développement d’application [Partir des exigences et aller jusqu'à une
                                    application complète]</b>
                                <ul style={{color: 'var(--secondary-color)'}}>
                                    <li>Élaborer et implémenter les spécifications fonctionnelles et non fonctionnelles
                                        à partir des exigences
                                    </li>
                                    <li>Appliquer des principes d'accessibilité et d'ergonomie</li>
                                    <li>Adopter de bonnes pratiques de conception et de programmation</li>
                                    <li>Vérifier et valider la qualité de l'application par les tests</li>
                                </ul>
                            </li>
                            <li>
                                <b>Conduire un projet [Appliquer une démarche de suivi de projet en fonction des besoins
                                    métiers]</b>
                                <ul style={{color: 'var(--secondary-color)'}}>
                                    <li>Formaliser les besoins du client et de l'utilisateur</li>
                                </ul>
                            </li>
                        </ul>
                    </p>

                    <p>L'application doit proposer la saisie des imputations par les chefs de projets et responsables
                        scientifiques.</p>

                    <p>Sur ses maquettes, la designeuse UI/UX proposa d'utiliser un champ de saisie
                        simple par semaine (permettant d'y remplir l'imputation totale de la semaine). Cette solution,
                        selon moi présentait deux principaux problèmes :</p>
                    <ul>
                        <li><b>Manque de simplicité :</b> Les responsables doivent, pour chaque consultant, calculer la
                            somme de tête, puis remplir le champ de saisie.
                        </li>
                        <li><b>Manque de granularité :</b> Il est impossible de voir les imputations par jour, ce qui
                            peut
                            être utile pour identifier des erreurs ou des oublis.
                        </li>
                    </ul>
                    <p>J'ai donc proposé de réaliser, sur l'inspiration d'un composant de notation, un composant
                        représentant la journée et permettant, en un clic de remplir la valeur de la journée.</p>

                    <p>Il dispose de plusieurs paramètres de configuration :</p>
                    <ul>
                        <li>La valeur de la journée imputée</li>
                        <li>La valeur des imputations de ce consultant sur un autre projet (cas d'un consultant
                            travaillant
                            sur plusieurs projets - pour éviter la saisie supérieure à 100% d'une journée)
                        </li>
                        <li>Si le jour est désactivé (jour composant une journée ... sur deux mois)</li>
                        <li>Si le jour est férié</li>
                    </ul>

                    <img src={ImputationComponentExample} style={{
                        height: '100px', maxWidth: '100%', borderRadius: 20,
                    }}
                         alt="Exemples de composant Imputation"/>

                    <div
                        style={{
                            backgroundColor: 'var(--light-color)',
                            color: 'var(--dark-color)',
                            padding: '5px 20px',
                            borderRadius: 20,
                            marginTop: 20
                        }}
                    >
                        <p>Cette solution offre de nombreux avantages (polyvalence, configuration plus exhaustive,
                            accessibilité,...) et permet de <b>palier les deux principaux problèmes de la solution
                                initialement proposée</b>.</p>
                    </div>
                </CustomSection>
            </motion.div>
            <motion.div
                initial={{opacity: 0, x: 100}}
                whileInView={{opacity: 1, x: 0}}
                transition={{duration: 0.6}}
            >
                <CustomSection title="Structure de l'application">
                    <p style={{color: "var(--primary-color)"}}>
                        Compétences mises en œuvre :
                        <ul>
                            <li>
                                <b>Réaliser un développement d’application [Partir des exigences et aller jusqu'à une
                                    application complète]</b>
                                <ul style={{color: 'var(--secondary-color)'}}>
                                    <li>Adopter de bonnes pratiques de conception et de programmation</li>
                                    <li>Vérifier et valider la qualité de l'application par les tests</li>
                                </ul>
                            </li>
                            <li>
                                <b>Optimiser des applications [Sélectionner les algorithmes adéquats pour répondre à un problème donné]</b>
                                <ul style={{color: 'var(--secondary-color)'}}>
                                    <li>Évaluer l'impact environnemental et sociétal des solutions proposées</li>
                                </ul>
                            </li>
                            <li>
                                <b>Administrer des systèmes informatiques communicants complexes [Déployer des services dans une architecture réseau]</b>
                                <ul style={{color: 'var(--secondary-color)'}}>
                                    <li>Développer des applications communicantes</li>
                                    <li>Sécuriser les services et données d'un système</li>
                                </ul>
                            </li>
                            <li>
                                <b>Gérer des données de l'information [Optimiser une base de données, interagir avec une application et mettre en œuvre la sécurité]</b>
                                <ul style={{color: 'var(--secondary-color)'}}>
                                    <li>Organiser la restitution de données à travers la programmation et la visualisation</li>
                                    <li>Manipuler des données hétérogènes</li>
                                </ul>
                            </li>
                        </ul>
                    </p>

                    <p>L'application (front-end) est structurée de la manière suivante :</p>
                    <ul>
                        <li>Un dossier <b>components</b> qui contient tous les composants réutilisables.</li>
                        <li>Un dossier <b>pages</b> qui contient les pages de l'application.</li>
                        <li>Un dossier <b>services</b> qui contient les services qui permettent de communiquer avec le
                            back-end ou d'autres ressources/apis.
                        </li>
                        <li>Un dossier <b>utils</b> qui contient des fonctions utilitaires.</li>
                    </ul>

                    <p>On parle ici de <a href="https://fr.wikipedia.org/wiki/S%C3%A9paration_des_pr%C3%A9occupations"
                                          target="_blank" rel="noreferrer"><i>Separation of concerns</i></a> : une bonne
                        pratique largement reconnue et mise en oeuvre dans l'ingénierie logicielle.</p>

                    <div
                        style={{
                            backgroundColor: 'var(--light-color)',
                            color: 'var(--dark-color)',
                            padding: '5px 20px',
                            borderRadius: 20,
                            marginTop: 20
                        }}
                    >
                        <p>La séparation des méthodes communiquant avec le back-end dans des fichiers "services"
                            permet, de <b>séparer la <i>logique</i> de l'application de la partie
                                visuelle</b>,
                            de <b>réutiliser ces méthodes dans d'autres composants</b> et de <b>faciliter la
                                maintenance</b> de
                            l'application.</p>
                    </div>

                    <p>> Si un endpoint du back-end est modifié, il suffit de modifier la méthode dans le fichier
                        service
                        correspondant pour que tous les composants utilisant cette méthode bénéficient de cette mise à
                        jour.</p>

                    <br/>

                    <p>Pour rendre le développement des services plus accessibles et uniformes, chaque service repose
                        sur une instance d'<i>axios</i> configurée pour communiquer avec le back-end.</p>

                    <p>J'ai configuré cette instance, par des intercepteurs de requêtes et réponses, pour répondre aux
                        attentes suivantes, de façon automatique, sans configuration supplémentaire nécessaire :</p>
                    <ul>
                        <li>Les requêtes sont envoyées avec un <b>token d'authentification</b> si l'utilisateur est
                            connecté.
                        </li>
                        <li>Les réponses sont interceptées pour gérer les erreurs de manière uniforme.</li>
                        <li>Certaines réponses sont stockées en cache pour éviter de demander plusieurs fois la même ressource à l'API (il est précisé les ressources à conserver - celles qui évoluent peu).</li>
                    </ul>
                </CustomSection>
            </motion.div>
            <motion.div
                initial={{opacity: 0, x: 100}}
                whileInView={{opacity: 1, x: 0}}
                transition={{duration: 0.6}}
            >
                <CustomSection title="Analyse et Résolution de problèmes">
                    <h3>Des appels à l'API un peu trop répétés.</h3>
                    <p style={{color: "var(--primary-color)"}}>
                        Compétences mises en œuvre :
                        <ul>
                            <li>
                                <b>Réaliser un développement d’application [Partir des exigences et aller jusqu'à une
                                    application complète]</b>
                                <ul style={{color: 'var(--secondary-color)'}}>
                                    <li>Adopter de bonnes pratiques de conception et de programmation</li>
                                </ul>
                            </li>
                            <li>
                                <b>Optimiser des applications [Sélectionner les algorithmes adéquats pour répondre à un
                                    problème donné]</b>
                                <ul style={{color: 'var(--secondary-color)'}}>
                                    <li>Évaluer l'impact environnemental et sociétal des solutions proposées</li>
                                </ul>
                            </li>
                        </ul>
                    </p>

                    <p>Un problème est apparu lors de simples tests manuels de l'application : <b>les appels à l'API
                        étaient trop nombreux</b>. En effet, chaque appel etait au moins doublé, voir beaucoup plus.</p>

                    <p>Ce problème, bien que très peu impactant pour notre version en développement, pourrait devenir
                        très
                        impactante sur les performances une fois poussée en production et utilisée par de nombreux
                        utilisateurs.</p>

                    <p>Après avoir très longuement analysé le code, fait de nombreux refactoring de composants, fenêtres,
                        méthodes..., j'ai finalement fait appel à mon tuteur en entreprise pour une assistance. Il m'a
                        appris qu'<b>en mode "développement", React double toutes les actions pour effectuer
                            des <i>Stress-tests</i></b>, permettant de tester l'application dans des cas de forte
                        affluence.
                    </p>

                    <p>Cette fonctionnalité ayant été introduite dans la dernière version de React, je n'avais pas
                        connaissance de son existence et celle-ci etait très peu documentée.</p>

                    <div
                        style={{
                            backgroundColor: 'var(--light-color)',
                            color: 'var(--dark-color)',
                            padding: '5px 20px',
                            borderRadius: 20,
                            marginTop: 20
                        }}
                    >
                        <p>Bien que ce problème m'ait fait perdre beaucoup de temps, il m'a permis de <b>renforcer mes
                            connaissances sur React</b> et de <b>comprendre le fonctionnement interne de la
                            librairie</b>.
                        </p>
                        <p>Il m'a également permis de parfaire le fonctionnement de certaines méthodes et
                            composants. <b>Une
                                perte de temps qui s'est révélée être bénéfique !</b></p>
                    </div>
                </CustomSection>
            </motion.div>
            <motion.div
                initial={{opacity: 0, x: 100}}
                whileInView={{opacity: 1, x: 0}}
                transition={{duration: 0.6}}
            >
                <CustomSection title="Déploiement et mise en production">
                    <p style={{color: "var(--primary-color)"}}>
                        Compétences mises en œuvre :
                        <ul>
                            <li>
                                <b>Réaliser un développement d’application [Partir des exigences et aller jusqu'à une
                                    application complète]</b>
                                <ul style={{color: 'var(--secondary-color)'}}>
                                    <li>Adopter de bonnes pratiques de conception et de programmation</li>
                                </ul>
                            </li>
                            <li>
                                <b>Administrer des systèmes informatiques communicants complexes [Déployer des services dans une architecture réseau]</b>
                                <ul style={{color: 'var(--secondary-color)'}}>
                                    <li>Utiliser la virtualisation</li>
                                </ul>
                            </li>
                        </ul>
                    </p>

                    <p>Dès l'obtention d'une version assez stable de l'application, nous avons décidé de la déployer
                        pour permettre à certains utilisateurs sélectionnés de la tester et de nous faire des
                        retours.</p>

                    <p>
                        Pour avoir un processus de déploiement automatisé, j'ai mis en place un pipeline de déploiement
                        avec le module CI/CD de <b>Azure DevOps</b>.
                    </p>

                    <img
                        src={AzurePipelines} width={500} style={{borderRadius: 20, marginLeft: 10}}
                        alt={"Graphique du pipeline de déploiement Azure Pipelines"}
                    />

                    <p>Le pipeline est composé de plusieurs étapes :</p>
                    <ul>
                        <li>La récupération du code source sur le dépôt git (SOURCE)</li>
                        <li>L'installation des dépendances</li>
                        <li>Les tests unitaires (TEST)</li>
                        <li>La construction de l’application <i>(deux images docker - une pour le backend, une autre pour le frontend)</i> (BUILD)</li>
                        <li>Le déploiement sur un serveur de test (DEPLOY)</li>
                    </ul>

                    <p>Lancer les tests unitaires à chaque modification du code permet de garantir que les modifications
                        ne
                        cassent pas le fonctionnement attendu de l'application. Cela évite la régression.</p>

                    <div
                        style={{
                            backgroundColor: 'var(--light-color)',
                            color: 'var(--dark-color)',
                            padding: '5px 20px',
                            borderRadius: 20,
                            marginTop: 20
                        }}
                    >
                        <p>Ce processus de déploiement automatique permet de garantir que l'application est toujours
                            fonctionnelle, à jour et de qualité, <b>sans intervention humaine</b> ultérieure.</p>
                    </div>
                </CustomSection>
            </motion.div>

            <motion.div
                initial={{opacity: 0, x: 100}}
                whileInView={{opacity: 1, x: 0}}
                transition={{duration: 0.6}}
            >
                <CustomSection title="Outils de développement et méthodes de travail en équipe">
                    <p style={{color: "var(--primary-color)"}}>
                        Compétences mises en œuvre :
                        <ul>
                            <li>
                                <b>Conduire un projet [Appliquer une démarche de suivi de projet en fonction des besoins métiers des clients et des utilisateurs]</b>
                                <ul style={{color: 'var(--secondary-color)'}}>
                                    <li>Formaliser les besoins du client et de l'utilisateur</li>
                                    <li>Identifier les critères de faisabilité d'un projet informatique</li>
                                    <li>Définir et mettre en œuvre une démarche de suivi de projet</li>
                                </ul>
                            </li>
                            <li>
                                <b>Collaborer au sein d'une équipe informatique [Situer son rôle et ses missions au sein d'une équipe informatique]</b>
                                <ul style={{color: 'var(--secondary-color)'}}>
                                    <li>Appliquer une démarche pour intégrer une équipe informatique au sein d'une organisation</li>
                                    <li>Mobiliser les compétences interpersonnelles pour intégrer une équipe informatique</li>
                                    <li>Rendre compte de son activité professionnelle</li>
                                </ul>
                            </li>
                        </ul>
                    </p>

                    <h3>Outils de développement</h3>

                    <p>Comme précisé précédemment, le projet a été réalisé en <b>React</b> (JavaScript) pour le
                        front-end et en <b>Django</b> (Python) pour le back-end. Le SGBDR utilisé est <b>PostgreSQL</b>.
                    </p>

                    <p>Le code source est hébergé sur deux dépôts git intégrés à <b>Azure DevOps</b> : un pour le
                        front-end et un pour le back-end.</p>

                    <p>J'ai choisit d'utiliser les IDEs JetBrains, accessibles et agréables d'utilisation. J'ai
                        utilisé <b>WebStorm</b> pour le front-end et <b>PyCharm</b> pour le back-end. Cette liberté de
                        choix m'a permis de travailler dans un environnement familier et de gagner en efficacité.</p>

                    <p>Les maquettes ont été réalisées avec <b>Figma</b>, un outil de design collaboratif.</p>

                    <p>Les tests unitaires ont été réalisés avec <b>Jest</b>, un framework de test pour JavaScript.</p>

                    <p>Le déploiement a été réalisé avec <b>Azure Pipelines</b>, un outil de CI/CD performant, intégré aux outils Azure et Microsoft.</p>

                    <p>
                        Les collaborateurs affectés à ce projet étaient répartis sur plusieurs sites en France.
                        Toutes nos communications se sont faites via <b>Teams</b>, l'outil de communication professionnel
                        de Microsoft.
                        Cela nous a permis de partager des fichiers, de discuter en groupe ou en privé, de partager des
                        écrans, de planifier des réunions...
                    </p>

                    <div
                        style={{
                            backgroundColor: 'var(--light-color)',
                            color: 'var(--dark-color)',
                            padding: '5px 20px',
                            borderRadius: 20,
                            marginTop: 20
                        }}
                    >
                        <p>Tous ces outils et technologies ont été choisis pour leur <b>efficacité</b>,
                            leur <b>facilité</b> et leur <b>fiabilité</b>.
                            Cet eventail large d'outils m'a permis de <b>gagner en efficacité</b> et de <b>travailler
                                dans un environnement professionnel complet</b>.</p>
                    </div>

                    <h3>Méthode de travail</h3>

                    <p>Nous nous sommes basés sur l'approche Agile <b>Scrum</b> (avec une adaptation type Lean Startup)
                        pour la gestion du projet.</p>

                    <img
                        src={ScrumMethodology} width={600}
                        style={{borderRadius: 20, marginLeft: 10, backgroundColor: 'var(--light-color)'}}
                        alt={"Schéma de la méthode Scrum"}
                    />


                    <p>Le projet était découpé en <b>sprints</b> de 2 à 4 semaines, avec des objectifs clairs et des
                        livrables à la fin de chaque sprint.</p>

                    <p>Des <b>points quotidiens</b> étaient organisés pour faire le point sur le travail réalisé la
                        veille, les
                        difficultés rencontrées et les objectifs de la journée <i
                            style={{color: 'var(--accent-color)'}}>(DALY SCRUM)</i>.</p>
                    <p>Des <b>points hebdomadaires</b> étaient également organisés pour faire le point sur le travail de
                        la semaine, de partager nos idées et de prendre des décisions sur les orientations à prendre.
                    </p>

                    <p>Chaque sprint (d'une durée de 2 à 4 semaines) était cloturé par une <b>rétrospective</b> pour
                        faire le
                        point sur le sprint, identifier les points positifs et négatifs, et prendre des décisions pour
                        améliorer nos actions <i style={{color: 'var(--accent-color)'}}>(SPRINT REVIEW / SPRINT
                            RETROSPECTIVE)</i>. Cette réunion était également l'occasion pour établir les nouveaux
                        objectifs du sprint suivant et d'estimer leurs difficultés <i
                            style={{color: 'var(--accent-color)'}}>(SPRINT
                            PLANNING)</i>.</p>

                    <div
                        style={{
                            backgroundColor: 'var(--light-color)',
                            color: 'var(--dark-color)',
                            padding: '5px 20px',
                            borderRadius: 20,
                            marginTop: 20
                        }}
                    >
                        <p>J'ai participé à toutes ces réunions, et j'ai pu constater que cette méthode de travail était
                            très efficace pour conserver un <b>dynamisme</b> et une <b>cohésion d'équipe</b>.</p>
                    </div>
                </CustomSection>
            </motion.div>
        </>
    )
}