import Styles from './PageEnConstruction.module.css';
import {MdConstruction} from "react-icons/md";
import {motion} from "framer-motion";

export default function PageEnConstruction() {
    return (
        <motion.div className={Styles.page}
                    initial={{opacity: 0, y: 100}}
                    animate={{opacity: 1, y: 0}}
                    exit={{opacity: 0, y: -100}}
                    transition={{duration: 0.5}}
        >
            <MdConstruction/>
            <h2>Page en construction...</h2>
        </motion.div>
    );
}