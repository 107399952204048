import CustomSection from "../../../components/CustomSection";
import AnimatedPage from "../../../AnimatedPage";

export default function BUT2() {
    return (
        <AnimatedPage>
            <h1 style={{color: 'var(--primary-color)'}}>Réferentiel de compétences</h1>
            <CustomSection title="Réaliser un développement d’application">
                <p><b>Partir des exigences et aller jusqu'à une application complète</b></p>
                <ul>
                    <li>Concevoir et développer des applications complètes en partant des exigences initiales.</li>
                    <li>Mettre en œuvre l'ensemble du processus de développement, de la conception à la réalisation.
                    </li>
                </ul>
            </CustomSection>

            <CustomSection title="Optimiser des applications informatiques">
                <p><b>Sélectionner les algorithmes adéquats pour répondre à un problème donné</b></p>
                <ul>
                    <li>Sélectionner judicieusement les algorithmes adaptés pour résoudre efficacement des problèmes spécifiques.</li>
                    <li>Mettre en place des techniques d'optimisation pour améliorer les performances des applications.</li>
                </ul>
            </CustomSection>

            <CustomSection title="Administrer des systèmes informatiques communicants complexes">
                <p><b>Déployer des services dans une architecture réseau</b></p>
                <ul>
                    <li>Déployer des services dans des architectures réseau complexes.</li>
                    <li>Assurer l'administration et la gestion de systèmes informatiques intégrés et communicants.</li>
                </ul>
            </CustomSection>

            <CustomSection title="Gérer des données de l’information">
                <p><b>Optimiser une base de données, interagir avec une application et mettre en œuvre la sécurité</b>
                </p>
                <ul>
                    <li>Optimiser les bases de données pour garantir des performances optimales.</li>
                    <li>Assurer l'interaction efficace entre les bases de données et les applications tout en mettant en
                        œuvre des mesures de sécurité.
                    </li>
                </ul>
            </CustomSection>

            <CustomSection title="Conduire un projet">
                <p><b>Appliquer une démarche de suivi de projet en fonction des besoins métiers des clients et des
                    utilisateurs</b></p>
                <ul>
                    <li>Appliquer une démarche de suivi de projet en accord avec les besoins métiers des clients et des
                        utilisateurs (ex. Agile).
                    </li>
                    <li>Diriger efficacement un projet informatique en tenant compte des exigences spécifiques.</li>
                </ul>
            </CustomSection>

            <CustomSection title="Travailler dans une équipe informatique">
                <p><b>Situer son rôle et ses missions au sein d'une équipe informatique</b></p>
                <ul>
                    <li>Comprendre et situer son rôle et ses missions au sein d'une équipe informatique.</li>
                    <li>Collaborer de manière efficace avec les membres de l'équipe pour atteindre les objectifs du
                        projet.
                    </li>
                </ul>
            </CustomSection>
        </AnimatedPage>
    );
}