import PhotoProfil from '../../assets/images/NathanBOSCHI.png'
import Styles from './CVPage.module.css';
import AnimatedPage from "../../AnimatedPage";
import CustomSection from "../../components/CustomSection";

export default function CVPage() {
    return (<AnimatedPage>
        <div className={Styles.top}>
            <img src={PhotoProfil} alt=""/>

            <div>
                <p>
                    Etudiant en deuxième année de BUT Informatique à l’IUT Nord Franche-Comté à Belfort. Je
                    développe
                    mes compétences dans différents langages de programmation (JavaScript, Java, Python, HTML, CSS)
                    durant mon cursus à l’IUT et de façon autodidacte.
                    Mes expériences m’ont permis de développer mes compétences personnelles et professionnelles
                    (Gestion
                    de projet, Autonomie, Précision, Assiduité).
                </p>
                <p><b>
                    Pour développer mes compétences et faire un lien avec le monde professionnel, je suis à la
                    recherche
                    d’une alternance dès l’année prochaine.
                </b></p>
            </div>
        </div>

        <div className={Styles.page}>
            <CustomSection title="Formation" className={Styles.section}>
                <div className={Styles.element}>
                    <span className={Styles.dates}>En cours</span>
                    <span className={Styles.details}>
                        <b>Bachelor Universitaire de Technologie - Informatique</b><br/>
                        Université de Franche-Comté, IUT Nord Franche-Comté, Belfort

                        <p>1<sup>ere</sup> et 2<sup>ème</sup> années validées - 3<sup>ème</sup> année en cours</p>
                    </span>
                </div>

                <div className={Styles.element}>
                    <span className={Styles.dates}>2022</span>
                    <span className={Styles.details}>
                        <b>Baccalauréat Technologique STI2D - Système d’Information et Numérique</b>, Mention Bien <br/>
                        Lycée Polyvalent Germaine Tillon, Montbéliard</span>
                </div>
            </CustomSection>

            <CustomSection title="Certifications" className={Styles.section}>
                <div className={Styles.element}>
                    <span className={Styles.dates}>Jan. 2023</span>
                    <span className={Styles.details}><b>EF SET Certificate</b> - 60 Points - Niveau B2 Upper Intermediate (Anglais) - <a
                        className="link" href="https://www.efset.org/cert/VAx3T2"
                        target="_blank" rel="noreferrer">Lien vers la certification</a></span>
                </div>

                <div className={Styles.element}>
                    <span className={Styles.dates}>2022</span>
                    <span className={Styles.details}><b>Certification PIX</b> - 248 points</span>
                </div>
                <div className={Styles.element}>
                    <span className={Styles.dates}>Mai 2018</span>
                    <span
                        className={Styles.details}><b>Certificat Prévention et Secours Civiques de niveau 1</b> (PSC1)</span>
                </div>
            </CustomSection>

            <CustomSection title="Expérience et Bénévolat" className={Styles.section + ' ' + Styles.experience}>


                <article className={Styles.element}>
                    <span className={Styles.dates}>
                        <span className={Styles.start}>Avril 2024</span>
                        <span className={Styles.end}>Juillet 2024</span>
                    </span>
                    <span className={Styles.details}>
                        <img src="https://logo.clearbit.com/sogeti.com" alt="Logo Sogeti"/>
                        <span className={Styles.title}>
                            Développeur full-stack, Sogeti, Belfort
                        </span>
                        <p>Stage</p>
                        <div className={Styles.infos}>
                            <p>Sur un projet de développement d'une application de gestion de projets internes.</p>
                            <span>Développement front avec React (JS)</span>
                            <span>Développement back avec Django (Python) et SGBDR Postrgresql</span>
                        </div>
                    </span>
                </article>

                <article className={Styles.element}>
                    <span className={Styles.dates}>
                        <span className={Styles.start}>Septembre 2023</span>
                        <span className={Styles.end}>Octobre 2023</span>
                    </span>
                    <span className={Styles.details}>
                        <img src="https://logo.clearbit.com/stellantis.com" alt="Logo Stellantis"/>
                        <span className={Styles.title}>
                            Développeur full-stack, Stellantis, Vesoul
                        </span>
                        <p>Apprentissage</p>
                        <div className={Styles.infos}>
                            <p>Sur un projet de développement d'une application permettant la digitalisation des standards.</p>
                            <span>Analyse et recueil des besoins - Construction du cahier des charges</span>
                            <span>Planification du projet</span>
                            <span>Modélisation de la structure des données</span>
                        </div>
                    </span>
                </article>

                <article className={Styles.element}>
                    <span className={Styles.dates}>
                        <span className={Styles.start}>Juillet 2023</span>
                        <span className={Styles.end}>Août 2023</span>
                    </span>
                    <span className={Styles.details}>
                        <img src="https://logo.clearbit.com/gouvernement.fr" alt="Logo Gouvernement francais"/>

                        <span className={Styles.title}>
                            Agent d'Accueil, DDFIP du Territoire de Belfort, Belfort
                        </span>
                        <p>Contrat à Durée Déterminée (CDD)</p>
                        <div className={Styles.infos}>
                            <span>Accompagnement à la création et à la prise en main de l’espace personnel</span>
                            <span>Accueil primaire et Accompagnement des contribuables à la Déclaration d’occupation des biens immobiliers</span>
                            <span>Saisie des déclarations reçues sur l'application métier dédiée</span>
                            <span>Courrier, Classement et Archivage</span>
                        </div>
                    </span>
                </article>

                <article className={Styles.element}>
                    <span className={Styles.dates}>
                        <span className={Styles.start}>Aout 2021</span>
                        <span className={Styles.end}>Aujourd'hui</span>
                    </span>
                    <span className={Styles.details}>
                        <span className={Styles.title}>
                            Développement des usages du numérique, Commune de Bretigney, Bretigney
                        </span>
                            <p>Bénévolat</p>
                        <div className={Styles.infos}>
                            <span>Construction et présentation au conseil municipal d’un projet global de transformation numérique</span>
                            <span>Construction et suivi de dossiers de demande de subvention</span>
                            <span>Mise en place de nouvelles machines client, configuration des solutions, conception et développement d’application web (Progiciel Gestion des Communications).</span>
                            <span>Formation à l’utilisation des nouveaux outils</span>
                        </div>
                    </span>
                </article>
            </CustomSection>

            <CustomSection title="Projets tuteurés" className={Styles.section}>
                <h4 className="secondary">Situations d'Apntissage et d'Evaluation (SAE) - BUT Informatique</h4>

                <div className={Styles.element}>
                    <span className={Styles.dates}>Semestre 2</span>
                    <span className={Styles.details}>
                    <b>SAE S1. 1.2.6 - <a href="./portfolio.html#StuckWin">Jeu de la Mérelle</a></b> - <span
                        className="blue">/ALGO/Anglais/Droit/</span> -
                    Développement du jeu de la Mérelle (Version Console et Graphique) - Développement d'ordinateurs de jeu - Etudes des règles de droit liées à la création d'un jeu
                        {/*TODO Ajouter la partie anglais, préciser la partie DROIT*/}
                </span>
                </div>

                <div className={Styles.element}>
                    <span className={Styles.dates}>Semestre 2</span>
                    <span className={Styles.details}>
                <b>SAE S2. 3.4.5 - <a href="./portfolio.html#BikeUFC">BikeUFC</a></b> - <span className="blue">/BDD/WEB/Système/Gestion de Projet/</span> -
                Développement d'un site e-commerce (Flask) - SQL - Visualisation de données - Gestion de projet
                        {/*TODO Ajouter la partie système de la SAE*/}
            </span>
                </div>


                <div className={Styles.element}>
                    <span className={Styles.dates}>Semestre 1</span>
                    <span className={Styles.details}>
                <b>SAE S1. 01/02 - <a href="./portfolio.html#StuckWin">Jeu StuckWin</a></b> - <span
                        className="blue">/ALGO/</span> -
                Développement d'un mini-jeu - Développement de deux algorithmes type mini-IA pour un joueur 'Ordinateur" - Comparaison d'algorithmes.
            </span>
                </div>

                <div className={Styles.element}>
                    <span className={Styles.dates}>Semestre 1</span>
                    <span className={Styles.details}>
                <b>SAE S1. 03/04 - <a href="./portfolio.html#EcoQuartier">EcoQuarier</a></b> - <span
                        className="blue">/BDD/Système/</span> -
                Mise en place d'une base de données pour permettre à une commune de mesurer différents indicatifs d'impact sur l'environnement d'un écoquartier - Proposition de configurations machines et configuration d'un poste de développement
            </span>
                </div>

                <div className={Styles.element}>
                    <span className={Styles.dates}>Semestre 1</span>
                    <span className={Styles.details}>
                <b>SAE S1. 05/06 - <a href="./portfolio.html#ReCapgemini">ReCapgemini</a></b> - <span className="blue">/WEB/Economie/Communication Professionnelle/</span> -
                Présentation organisationnelle et économique d'une entreprise du domaine numérique (Capgémini) au travers d'un site vitrine et d'un rapport économique.
            </span>
                </div>

            </CustomSection>
        </div>
    </AnimatedPage>);
}