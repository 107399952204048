import TabBar from "../../components/TabBar";
import Styles from "./Portfolio.module.css";
import {Outlet} from "react-router-dom";

const navLinks = [
    {label: 'SAE - ReCapgemini', route: 'recapgemini'},
    {label: 'SAE - StuckWin', route: 'stuckwin'},
    {label: 'SAE - Ecoquartier', route: 'ecoquartier'},
    {label: 'SAE - BikeUFC', route: 'bikeufc'},
    {label: 'SAE - Merelle', route: 'merelle'},
];

export default function PortfolioBUT1() {
    return (
        <>
            <TabBar navItems={navLinks} second/>

            <div className={Styles.mainContent}>
                <Outlet/>
            </div>
        </>
    );
}