import {Link} from "react-router-dom";
import Styles from './NavBar.module.css';
import {useLocation} from "react-router-dom";
import {MdMenu} from "react-icons/md";
import {useEffect, useState} from "react";

export default function NavBar({navItems = []}) {

    const location = useLocation();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        setIsMenuOpen(false);
    }, [location.pathname]);

    return (
        <header>
            <h1>
                <span className={Styles.primary}>@</span>
                Nathan
                <span className={Styles.secondary}>.</span>
                BOSCHI
                <span className={Styles.secondary}>.</span>
                <span className={Styles.primary}>_</span>
            </h1>

            <nav>
                <MdMenu className={Styles.menuIcon} style={{height: "30px", width: "30px"}} onClick={() => {
                    setIsMenuOpen(!isMenuOpen)
                }}/>
                <ul className={!isMenuOpen ? Styles.navList : Styles.navList + ' ' + Styles.active}>
                    {navItems.map((navItem) => (
                        <li key={navItem.route} className={Styles.navItem}>
                            <Link
                                className={((navItem.route === '/' && location.pathname === navItem.route) || (navItem.route !== '/' && location.pathname.includes(navItem.route))) ? Styles.active : ''}
                                to={navItem.route}
                            >
                                {navItem.label}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
        </header>
    );
}